<template>
  <div class="switching">
    <Header />
    <b-container class="switching-container">
      <b-row class="justify-content-md-start back-nav">
        <div class="col-lg-12 col-md-6">
          <b-link :to="'/switching' + '/' + fund_code_out + '/' + fund_code_in"
            ><b-icon class="mx-2" icon="chevron-left"></b-icon
            ><span>Kembali</span></b-link
          >
        </div>
      </b-row>

      <b-row class="justify-content-md-center p-4">
        <b-card class="switching-order p-4">
          <b-form>
            <div class="switching-title">
              <b-row class="justify-content-center my-2">
                <h4>Alihkan Produk</h4>
              </b-row>
            </div>
            <div class="switching-from">
              <b-row class="mt-4 mb-2">
                <div class="col-6 my-auto text-left">
                  <p class="title">Dari</p>
                  <p class="fund-name">{{ portfolio.product.fund_name }}</p>
                  <h2 class="fund-type">
                    {{ portfolio.product.fund_type }}
                  </h2>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="total-unit">{{ formatNab(switch_unit) }} unit</p>
                </div>
              </b-row>
            </div>
            <div class="total-switching">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Jumlah Pengalihan</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ portfolio.product.fund_ccy
                    }}{{ formatPrice(switch_nominal) }}
                  </p>
                </div>
              </b-row>
            </div>
            <hr />

            <div class="nav-unit">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">
                    NAV/unit,
                    {{ moment(porto_detail.nav_date).format(" DD MMM YYYY") }}
                  </p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ portfolio.product.fund_ccy
                    }}{{ formatNab(porto_detail.nav_per_unit) }}
                  </p>
                </div>
              </b-row>
            </div>
            <img src="@/assets/img/icons/transactions/arrow-down-circle.webp" />
            <b-row class="mt-4">
              <div class="switching-to">
                <p class="title ml-3">Ke</p>
              </div>
            </b-row>
            <div class="fund-name">
              <b-row>
                <div class="col-2 col-lg-1 text-left mb-2">
                  <div v-if="product.image == null">
                    <img
                      :src="
                        'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                        product.fund_name
                      "
                    />
                  </div>
                  <div v-else>
                    <img
                      :src="`${imageCore}/images/products/${product.image}`"
                      alt="Fund Image"
                    />
                  </div>
                </div>
                <div class="col-10 text-left px-4">
                  <h4>
                    {{ product.fund_name }}
                  </h4>
                  <p>{{ product.fund_type }}</p>
                </div>
              </b-row>
            </div>

            <div class="total-switching">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Jumlah Pengalihan</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ product.fund_ccy }}{{ formatPrice(switch_nominal) }}
                  </p>
                </div>
              </b-row>
            </div>
            <hr />

            <div class="nav-unit">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">
                    NAV/unit,
                    {{ moment(product.nav.nav_date).format(" DD MMM YYYY") }}
                  </p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ product.fund_ccy
                    }}{{ formatNab(product.nav.nav_per_unit) }}
                  </p>
                </div>
              </b-row>
            </div>
            <hr />

            <div class="estimate-accepted">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="estimate-title">Estimasi unit diterima</p>
                  <p class="estimate-date">
                    Harga/unit
                    {{ moment(product.nav.nav_date).format(" DD MMM YYYY") }}
                  </p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ formatNab(switch_nominal / product.nav.nav_per_unit) }}
                    unit
                  </p>
                </div>
              </b-row>
            </div>
            <hr />

            <!-- <div class="switching-cost">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Biaya Pengalihan</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p v-if="user.individual.sales_id != null" class="variant">
                    {{
                      product.fee_switch_text ? product.fee_switch_text : "-"
                    }}
                  </p>
                  <p v-else class="value">Gratis</p>
                </div>
              </b-row>
            </div> -->
            <hr />
            <div class="total-switching">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Total</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ portfolio.product.fund_ccy
                    }}{{ formatPrice(switch_nominal) }}
                  </p>
                </div>
              </b-row>
            </div>
            <!-- <div class="total-purchase">
              <b-row class="mt-2">
                <b-col class="my-auto">
                  <p class="variant">Total</p>
                </b-col>
                <b-col class="my-auto">
                  <p class="value">
                    {{ product.fund_ccy }}{{ invest_nominal | formatCurrency }}
                  </p>
                </b-col>
              </b-row>
            </div> -->

            <div class="price-terms mt-4">
              <b-row>
                <div class="col-lg-12 col-md-6">
                  <p>
                    <img
                      src="@/assets/img/icons/product/transaction-rules.svg"
                      alt="Ketentuan Transaksi"
                      class="mx-2"
                    />Ketentuan Transaksi
                  </p>
                </div>
              </b-row>
            </div>
            <div class="transaction-time-before ml-3">
              <b-row class="mt-2">
                <p class="before">Transaksi sebelum 13:00 WIB</p>
              </b-row>
              <b-row>
                <p class="before-detail text-justify">
                  Batas waktu konfirmasi Pengalihan Reksa Dana paling lambat
                  diterima dan tercatat oleh Aplikasi CGS iTrade Fund adalah pukul
                  13:00 WIB untuk diproses menggunakan harga NAV/Unit di hari
                  bursa yang sama.
                </p>
              </b-row>
            </div>
            <hr />
            <div class="transaction-time-after ml-3">
              <b-row class="mt-2">
                <p class="after">Transaksi setelah 13:00 WIB</p>
              </b-row>
              <b-row>
                <p class="after-detail text-justify">
                  Transaksi Pengalihan yang diterima dan tercatat melebihi batas
                  waktu pukul 13:00 WIB akan diproses dengan menggunakan harga
                  NAV/Unit hari bursa berikutnya.
                </p>
              </b-row>
            </div>
            <hr />
            <div class="transaction-time-before ml-3">
              <b-row class="mt-2">
                <p class="before">Biaya dan Perpajakan</p>
              </b-row>
              <b-row>
                <p class="before-detail">
                  Biaya dan Perpajakan akan timbul atas dasar ketentuan
                  peraturan perundangan-undangan yang berlaku, sesuai dengan
                  transaksi yang dimiliki.
                </p>
              </b-row>
            </div>
            <hr />
            <div class="agreement-checkbox">
              <b-row>
                <div class="custom-control custom-checkbox mb-4">
                  <input
                    id="agree"
                    type="checkbox"
                    value="accepted"
                    class="custom-control-input mr-2"
                    v-model="checked"
                  />
                  <label for="agree" class="custom-control-label text-justify">
                    Saya telah membaca, memahami, dan menyetujui seluruh isi
                    <b-link
                      :href="
                        imageCore +
                        '/prospectus/download/' +
                        portfolio.product.prospectus
                      "
                      class="prospectus-link text-danger text-decoration-none"
                      target="_blank"
                      >Prospektus</b-link
                    >
                    dan memahami risiko investasi yang saya buat.
                  </label>
                </div>
                <b-button
                  block
                  :disabled="!checked || loading"
                  @click="switchSubmit()"
                  class="btn-tertiary py-2"
                >
                  <div v-if="loading">
                    <div class="spinner-border spinner-border-sm"></div>
                    Loading
                  </div>
                  <div v-else>Alihkan Sekarang</div>
                </b-button>
              </b-row>
            </div>
          </b-form>
        </b-card>

        <div class="modal-success-switching">
          <b-modal
            v-model="show"
            size="md"
            class="modal-failed-register"
            centered
            hide-footer
            hide-header
            no-close-on-backdrop
          >
            <div class="d-block text-center">
              <img
                src="@/assets/img/icons/transactions/checked-blue.svg"
                alt=""
              />
              <h1 class="header-modal p-4 my-2">
                Pengalihan Anda akan segera diproses
              </h1>
              <p>Sekarang Anda tinggal menunggu verifikasi.</p>
              <b-row class="justify-content-center">
                <b-button to="/orders" block class="mt-3 mx-2 btn-tertiary py-2"
                  >Aktivitas Dalam Proses</b-button
                >
              </b-row>
            </div>
          </b-modal>
          <b-modal
            id="modal-failed-switch"
            v-model="failedSwitch"
            size="md"
            class="modal-failed-switch"
            centered
            hide-footer
            hide-header
            no-close-on-backdrop
          >
            <div class="d-block text-center">
              <img
                src="@/assets/img/ekyc/data-not-correct.svg"
                alt="data-not-correct"
              />
              <h1 class="header-modal p-4 my-2 text-dark">
                Unit tidak memenuhi minimal pengalihan produk
              </h1>
              <b-row class="justify-content-center">
                <b-button
                  :to="'/switching' + '/' + fund_code_out + '/' + fund_code_in"
                  block
                  class="mt-3 mx-2 btn-tertiary py-2"
                  >Kembali</b-button
                >
              </b-row>
            </div>
          </b-modal>
        </div>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";

import instance from "@/utils/api";

export default {
  name: "SwitchingReview",
  metaInfo: {
    title: "Tinjauan Pengalihan | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      show: false,
      product: {
        fund_name: "",
        nav: {
          nav_per_unit: "",
        },
      },
      portfolio: {
        product: {
          fund_name: "",
          nav: {
            nav_per_unit: 0,
            nav_date: 0,
          },
        },
      },
      fund_code_in: "",
      fund_code_out: "",
      checked: false,
      switch_unit: 0,
      switch_nominal: 0,
      switch_all_units: null,
      porto_detail: {},
      loading: false,
      processing: false,
      failedSwitch: false,
      imageCore: process.env.VUE_APP_IMAGE_URL,
    };
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      user: "auth/user",
      app_url: "app_url",
    }),
  },
  mounted() {
    if (localStorage.switch_nominal)
      this.switch_nominal = localStorage.switch_nominal;
    if (localStorage.switch_unit) this.switch_unit = localStorage.switch_unit;
    if (localStorage.switch_all_units)
      this.switch_all_units = localStorage.switch_all_units;
    if (this.switch_unit <= 0) {
      this.failedSwitch = true;
    }
  },
  created() {
    this.fund_code_in = this.$route.params.fund_code_in;
    this.fund_code_out = this.$route.params.fund_code_out;
    this.fetchFund();
    this.fetchPortfolio();
    // this.fetchFundOut();
  },
  methods: {
    async fetchFund() {
      let response = await axios({
        method: "GET",
        url: `${this.app_url}product/${this.fund_code_in}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });
      if (!response.data.data) {
        return this.$router.push("/NOT-FOUND");
      }
      this.product = response.data.data;
    },
    async fetchPortfolio() {
      if (this.user.institutional == null) {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}user-portfolio-product?type=Individual&id=${this.user.individual.id}&fund_code=${this.fund_code_out}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        this.portfolio = response.data.data.userPortfolio[0];
        this.porto_detail = response.data.data.portoDetail;
      } else {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}user-portfolio-product?type=Institutional&id=${this.user.institutional.id}&fund_code=${this.fund_code_out}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        this.portfolio = response.data.data.userPortfolio;
        this.porto_detail = response.data.data.portoDetail;
      }
    },

    switchSubmit: function () {
      if (!this.processing) {
        this.processing = true;
        let postData = {
          sa_code: "INV69",
          ifua_number:
            this.user.individual != null
              ? this.user.individual.ifua
              : this.user.institutional.ifua,
          switch_out_code: this.fund_code_out,
          switch_out_unit: parseFloat(this.switch_unit.replace(/,/g, "")),
          switch_out_nominal: parseFloat(this.switch_nominal),
          switch_in_code: this.fund_code_in,
          is_check: localStorage.is_check,
          // fee_nominal: 0,
          fee_type: "PERCENT", // "NOMINAL",
          switch_all_units: this.switch_all_units,
          individual_id:
            this.user.individual != null ? this.user.individual.id : null,
          institutional_id:
            this.user.institutional != null ? this.user.institutional.id : null,
          nav_per_unit: this.porto_detail.nav_per_unit,
          product_in_id: this.product.id,
          product_out_id: this.portfolio.product.id,
          nav_date: this.porto_detail.nav_date,
        };

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${this.bearer_token}`,
          },
        };
        // .post("2062", postData, axiosConfig)
        instance
          .post(`${this.app_url}switching-post`, postData, axiosConfig)
          .then((res) => {
            if (res.status == 200) {
              // console.log("RESPONSE RECEIVED: ", res);
              localStorage.removeItem("switch_nominal");
              localStorage.removeItem("switch_unit");
              localStorage.removeItem("switch_all_units");
              localStorage.removeItem("is_check");
              localStorage.clear();

              this.show = true;
              this.loading = !false;
            }
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.processing = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.switching {
  background: #f3f4f6;
}
.switching-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.switching-review {
  padding: 20px;
}

h1,
h2,
h3,
h4 p,
span {
  font-family: $inter;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #4b5563;
}

.back-nav {
  padding: 0px 260px 0px 260px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.switching-order {
  width: 600px;
}

.switching-title {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
}

.switching-from {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #374151;
    margin-bottom: 6px;
  }
  .fund-name {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    margin-bottom: 6px;
  }

  .fund-type {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }
  .unit-value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
  .total-unit {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.switching-to {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #374151;
    margin-bottom: 6px;
  }
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }
}

hr {
  margin: 0 !important;
}

.total-switching {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.estimate-accepted {
  .estimate-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
    margin-bottom: 2px;
  }

  .estimate-date {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.nav-unit {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.switching-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #38a169;
  }
}

.total-purchase {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.price-terms p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #111827;
}

.transaction-time-before {
  .before {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
  .before-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.transaction-time-after {
  .after {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
  .after-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.agreement-checkbox {
  margin-top: 40px;
  padding-left: 18px;
  padding-right: 18px;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $secondaryColor;
}

.custom-checkbox:active {
  box-shadow: none !important;
}
.custom-checkbox:focus {
  box-shadow: none !important;
}

.btn-tertiary {
  font-family: $inter;
  background: $tertiaryColor;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  color: #29064c;
  border: none;
}

.btn-tertiary:hover {
  background: $hoverTertiary;
}

.btn-tertiary:focus {
  box-shadow: none !important;
}

.btn-tertiary:active {
  background: $hoverTertiary !important;
  outline: none !important;
  color: #29064c !important;
}

.btn-primary {
  font-family: $inter;
  background: $primaryColor;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: white;
}

.btn-primary:hover {
  background: $hoverPrimary;
}

.btn-primary:focus {
  box-shadow: none !important;
}

.btn-primary:active {
  background: $hoverPrimary !important;
  outline: none !important;
  color: white !important;
}
.header-modal {
  font-size: 32px;
}
@media only screen and (max-width: 768px) {
  .back-nav {
    padding: 0px;
  }
  .fund-name {
    h4 {
      font-size: 16px !important;
    }
  }
  p {
    font-size: 14px !important;
  }
  label {
    font-size: 14px !important;
  }
  .header-modal {
    font-size: 16px !important;
  }
}
</style>
